<script setup lang="ts">
import { faCommentAltLines, faPhotoVideo, faUserCog } from '@fortawesome/pro-solid-svg-icons';

import CiProfileImage from '@/components/account/profile/CiProfileImage/CiProfileImage.vue';

/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
const userStore = useUserStore();
</script>

<template>
  <div class="account-sidebar">
    <div class="hidden lg:block">
      <CiProfileImage />
    </div>

    <client-only>
      <div
        v-if="userStore.user?.profile"
        class="mt-12 flex flex-col"
      >
        <nuxt-link
          class="group flex items-center border-b border-[#adb5bd] px-1 py-2 text-dark hover:font-bold hover:text-primary hover:no-underline"
          :to="localePath({ name: RBN_ACCOUNT_REVIEWS })"
        >
          <CiAwesomeIcon
            class="group-hover:fill-primary"
            :icon="faCommentAltLines"
            ratio="0.9"
          />
          <span class="ml-3">{{ $pgettext('Account navigation reviews', 'Reviews') }}</span>
        </nuxt-link>
        <nuxt-link
          class="group flex items-center border-b border-[#adb5bd] px-1 py-2 text-dark hover:font-bold hover:text-primary hover:no-underline"
          :to="localePath({ name: RBN_ACCOUNT_MEDIA })"
        >
          <CiAwesomeIcon
            class="group-hover:fill-primary"
            :icon="faPhotoVideo"
            ratio="0.9"
          />
          <span class="ml-3">{{ $pgettext('Account navigation pictures & videos', 'Pictures & Videos') }}</span>
        </nuxt-link>
        <nuxt-link
          class="group flex items-center border-b border-[#adb5bd] px-1 py-2 text-dark hover:font-bold hover:text-primary hover:no-underline"
          :to="localePath({ name: RBN_ACCOUNT_PROFILE })"
        >
          <CiAwesomeIcon
            class="group-hover:fill-primary"
            :icon="faUserCog"
            ratio="0.9"
          />
          <span class="ml-3">{{ $pgettext('Account navigation profile', 'Profile') }}</span>
        </nuxt-link>
      </div>
      <CiSkeletonItem
        v-else
        class="mt-12 h-[100px]"
      />
      <template #placeholder>
        <CiSkeletonItem class="mt-12 h-[100px]" />
      </template>
    </client-only>
  </div>
</template>

<style></style>
