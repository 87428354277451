<script setup lang="ts">
// layouts
import DefaultLayout from '@/layouts/default.vue';
import CiAccountSidebar from '@/components/account/CiAccountSidebar/CiAccountSidebar.vue';

/********************
 * COMPOSITIONS      *
 ********************/
const localePath = useLocalePath();
const route = useRoute();
const userStore = useUserStore();

/********************
 * HOOKS             *
 ********************/
onBeforeMount(async () => {
  if (!userStore.loggedIn) {
    return navigateTo(localePath({ name: RBN_LOGIN, query: { redirect: route.fullPath } }));
  }
});
</script>

<template>
  <default-layout :bg-gray="true">
    <div class="container grid grid-cols-4 gap-x-[30px] py-6 lg:py-12">
      <div class="col-span-4 hidden lg:col-span-1 lg:block">
        <CiAccountSidebar />
      </div>
      <div class="col-span-4 lg:col-span-3">
        <slot />
      </div>
    </div>
  </default-layout>
</template>
